import React, { useState, useEffect } from 'react';
import { addTokenToUrl } from 'common/src/utils/token';

export default () => {
  const [token, setToken] = useState(undefined);
  useEffect(() => {
    const key = 'Meatier.authToken';
    if (typeof window !== 'undefined') {
      try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key);
        setToken(item);
      } catch (error) {
        // If error also return initialValue
        console.error(error);
        return token;
      }
    }
  }, []);
  return token;
  // } catch (error) {
  //   // If error also return initialValue
  //   console.log(error);
  //   return token;
  // }
};
